import React from "react"
import downArrow from "../Images/downArrow.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
const MobileInstructions = ({ setAreInstructionsShown, setLocalStorage }) => {
    return (
        <div className='instructions'>
            <div className="instructions-text-container">
                <FontAwesomeIcon onClick={() => {
                    setAreInstructionsShown(false)
                    setLocalStorage("areInstructionsShown", false)
                }} icon={faXmark} className="close-pop-up-instructions black" />
                <p className='instruction-text instruction-header'>How to play The Riddley</p>
                <p className='instruction-text instruction-header-1'>Guess the mystery word using <br /> the fewest clues</p>
                <li
                    style={{ marginTop: -10 }}
                    className='instruction-text'>
                    Type your guess into the box <br /> <span style={{ marginLeft: 15 }}> and click "Enter".</span>
                </li>
                <li
                    style={{ marginTop: 3 }}
                    className='instruction-text'>
                    Guess the mystery word without <br /><span style={{ marginLeft: 15 }} className="instruction-bold"> making 3 mistakes!</span>
                </li>
                <p
                    style={{ marginTop: 5 }}
                    className='instruction-text instruction-header-2'>
                    Things to know:
                </p>
                <li
                    style={{ marginTop: -10 }}
                    className='instruction-text'>
                    Each clue revealed is more helpful than <span style={{ marginLeft: 15 }}> the previous one.</span>
                </li>
                <li
                    style={{ marginTop: 5 }}
                    className='instruction-text'>
                    The mystery word may be real or <br /><span style={{ marginLeft: 15 }}>fictional (Ex. Hogwarts).</span>
                </li>
                <li
                    style={{ marginTop: 5 }}
                    className='instruction-text'>
                    Look out for clues with wordplay or<br /><span style={{ marginLeft: 15 }}>double meanings!</span>
                </li>
                <div style={{ marginTop: 1, marginLeft: 9 }}>
                    {[...Array(4).keys()].map((index) => {
                        if (index === 0) {
                            return (
                                <div style={{
                                    display: "flex", marginBottom: -10
                                }}>
                                    <p
                                        style={{ marginLeft: 25, marginTop: 5, width: 23, height: 23 }}
                                        className={`grid-box grid-box-${index}`}>
                                    </p>
                                    <div style={{ display: "flex", marginTop: -10, marginLeft: 5 }} >
                                        <p>Most difficult Clues</p>
                                    </div>
                                </div>
                            )
                        }
                        else if (index === 1) {
                            return (
                                <div style={{
                                    display: "flex", marginBottom: -35, marginTop: 2
                                }}>
                                    <p
                                        style={{ marginLeft: 25, marginTop: 1, width: 23, height: 23 }}
                                        className={`grid-box grid-box-${index}`}>
                                    </p>
                                    <img className="down-arrow" alt="down arrpw" src={downArrow} />
                                    <div style={{ display: "flex", marginTop: -10, marginLeft: 5 }} >
                                    </div>
                                </div>
                            )
                        }
                        else if (index === 2) {
                            return (
                                <div style={{
                                    display: "flex", marginBottom: -15, marginTop: 2
                                }}>
                                    <p
                                        style={{ marginLeft: 25, marginTop: 2.5, width: 23, height: 23 }}
                                        className={`grid-box grid-box-${index}`}>
                                    </p>
                                    <div style={{ display: "flex", marginTop: -10, marginLeft: 5 }} >
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div style={{
                                display: "flex", marginBottom: -10
                            }}>
                                <p
                                    style={{ marginLeft: 25, marginTop: 20, width: 23, height: 23 }}
                                    className={`grid-box grid-box-${index}`}>
                                </p>
                                <div style={{ display: "flex", marginTop: 6, marginLeft: 5 }} >
                                    <p>Least difficult Clues</p>

                                </div>
                            </div>
                        )
                    })}
                </div>
                <button
                    onClick={() => setAreInstructionsShown(false)}
                    className="lets-play">
                    Let's Play!
                </button>
            </div>
        </div >
    )
}

export default MobileInstructions