const controllers = {
    puzzle: require("./controllers/puzzle"),
};

export const getPuzzleByNumber = async (puzzleNumber) => {
    return controllers.puzzle.getPuzzleByNumber(puzzleNumber)
}

export const submitPuzzle = async (puzzleNumber, submissionData) => {
    return controllers.puzzle.submitPuzzle(puzzleNumber, submissionData)
}

export const getStats = async (puzzleNumber) => {
    return controllers.puzzle.getStats(puzzleNumber)
}