import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect';
import "../Styles/crossword.css"
import { ContextProvider } from "../context"


const CrosswordLetter = ({
    guess, index, guessJustMade,
    handleCrosswordAnimation, isGameOver,
    isCrosswordRevealed, riddleWord, crossword, crosswordIndex, setCrosswordIndex }) => {

    const { width, height } = useContext(ContextProvider)


    const handleClassNameMobile = () => {
        if (index === crosswordIndex) {
            if (index === 0) {
                return "mobile-word mobile-word-active far-left-mobile "
            }
            else if (index === riddleWord.length - 1) {
                return "mobile-word mobile-word-active far-right"
            }
            else {
                return "mobile-word mobile-word-active"
            }
        }
        else {
            if (index === 0) {
                return "mobile-word far-left-mobile "
            }
            else if (index === riddleWord.length - 1) {
                return "mobile-word far-right"
            }
            else {
                return "mobile-word"
            }
        }
    }

    const handleClassName = () => {
        if (index === crosswordIndex) {
            if (index === 0) {
                return "word mobile-word-active far-left-mobile "
            }
            else if (index === riddleWord.length - 1) {
                return "word mobile-word-active far-right"
            }
            else {
                return "word mobile-word-active"
            }
        }
        else {
            if (index === 0) {
                return "word far-left-mobile "
            }
            else if (index === riddleWord.length - 1) {
                return "word far-right"
            }
            else {
                return "word"
            }
        }
    }

    const getBoxWidth = () => {
        if (isMobile) {
            return Math.floor(width / riddleWord.length) - 2
        }
        return 75
    }

    if (guessJustMade === false) {
        return (
            <div
                style={isCrosswordRevealed ? { backgroundColor: "#1DB954", width: getBoxWidth() } : guess.isGuessMade || isGameOver ? { backgroundColor: "#F88379", width: getBoxWidth() } : { width: getBoxWidth() }}
                className={isMobile ? handleClassNameMobile() : handleClassName()}>
                <span className={isMobile ? 'mobile-letter' : "letter"}>
                    {isCrosswordRevealed ? riddleWord[index].toUpperCase() : crossword[index] ? crossword[index].toUpperCase() : null}
                </span>
            </div>
        )
    }
    else if (guessJustMade === true) {
        return (
            <div
                style={guess.isGuessMade || isGameOver ? { backgroundColor: "#1DB954", width: getBoxWidth() } : { width: getBoxWidth() }}
                className={isMobile ? handleClassNameMobile() : handleClassName()}>
                <span className={isMobile ? 'mobile-letter' : "letter"}>
                    {crossword[index] ? crossword[index].toUpperCase() : null}
                </span>
            </div>
        )
    }
    return (
        <div
            onClick={() => setCrosswordIndex(index)}
            style={guess.isGuessMade ? handleCrosswordAnimation(index) : { width: getBoxWidth() }}
            className={isMobile ? handleClassNameMobile() : handleClassName()}>
            <span className={isMobile ? 'mobile-letter' : "letter"}>
                {crossword[index] ? crossword[index].toUpperCase() : null}
            </span>
        </div>
    )


}

export default CrosswordLetter