
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - puzzle
 * FUNCTION - getPuzzleByNumber
 * VERIFY - any user 
 * @param {*} puzzleNumber The puzzleNumber of the puzzle being fetched
 * @returns The puzzle data 
 */

export const getPuzzleByNumber = async (puzzleNumber) => {
    const response = await fetch(`${API_ROOT_URL}/puzzle/get_puzzle_by_number`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            puzzleNumber
        })
    })
    const result = await response.json();
    return result;
}