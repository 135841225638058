import React, { useState } from 'react'
import { isMobile } from 'react-device-detect';

const MessageAlert = ({ guessesRemaining, numberOfCluesRevealed, type,
    setPopUpMessage, popUpMessage, setLocalStorage, didUserFailPuzzle }) => {

    const [className, setClassName] = useState("message-alert")

    const handleGetMessage = () => {
        let message = ""
        if (type === "copiedToClipboard") {
            message = "Copied to Clipboard"
            // We need to return immediately here or else
            // We get an error b/c some of the props used later
            // on are not actually passed in by the Component 
            // That renders MessageAlert when type = "copiedToClipboard"
            return message
        }
        // Once the popUpMessage is set, don't change it
        // Otherwise the message will keep changing
        // on every re-render
        if (popUpMessage !== "") {
            return popUpMessage
        }
        // Ran out of lives
        if (didUserFailPuzzle) {
            const messages = ["Oof", "That sucks", "Go get dressed already", "Ouch",
                "Try reading a book or two", "Your better than this", "I'm not mad, just disappointed",]
            message = messages[Math.floor(Math.random() * 8)]
        }
        // A likely cheater
        else if (guessesRemaining === 3 && numberOfCluesRevealed === 0) {
            message = "Try not to cheat next time, thanks"
        }
        // If in the purple
        else if (numberOfCluesRevealed < 3) {
            const messages = ["Okayyyyy!", "How'd you do that?",
                "Today is your day!", "Smarty-pants", "Impressive", "Easy Money", "You've got bragging rights",
                "Stop showing off", "We get it, you're smart", "Put this one in the gc"]
            message = messages[Math.floor(Math.random() * 10)]
        }
        // If in the orange
        else if (numberOfCluesRevealed < 6) {
            const messages = ["Solid work", "You're kinda smart!",
                "Pretttty, pretttty good", "Who needs purple anyway?", "How `bout we get purple tomorrow?"]
            message = messages[Math.floor(Math.random() * 5)]
        }
        // If in the blue
        else if (numberOfCluesRevealed < 9) {
            const messages = ["Well done", "Respectable", "Alright, alright, alright", "You're average!", "Not great, but still good", "Good work", "Not bad. Not good"]
            message = messages[Math.floor(Math.random() * 7)]
        }
        // If in the green
        else if (numberOfCluesRevealed < 12) {
            const messages = ["Meh", "A for effort", "Room for improvement", "It could've been worse", "Don't put this one in the gc", "A dub's a dub"]
            message = messages[Math.floor(Math.random() * 6)]
        }
        setPopUpMessage(message)
        setLocalStorage("popUpMessage", message)

        return message
    }

    // setTimeout(() => {
    //     setClassName("message-alert-fade-out")
    // }, delayTime);

    const handleHeightOnPage = () => {
        if (type === "gameOverMessage") {
            if (isMobile) {
                return {
                    marginTop: 50,
                    postition: "relative",
                    width: 300,
                    height: 40,
                }
            }
            return {
                marginTop: 90
            }
        }
        else if (type === "copiedToClipboard") {
            return {
                marginTop: -70
            }
        }
    }
    return (
        <div style={handleHeightOnPage()} className={className}>
            <p className='message-alert-message-text'>{handleGetMessage()}</p>
        </div>
    )
}

export default MessageAlert