import React, { useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { isMobile } from 'react-device-detect';
import { ContextProvider } from "../context"


import "../Styles/clue.css"

const Clue = ({ currentClue, clue, question, clueDescription, handleGetNextClue, guess,
    handleGetPreviousClue, isEndGameAnimationRunning, isGame0ver, isSeeResultsShown }) => {

    const { width, height } = useContext(ContextProvider)


    const [touchStart, setTouchStart] = useState(null)

    const [touchEnd, setTouchEnd] = useState(null)

    const [isClueFlipped, setIsClueFlipped] = useState(false)

    const minSwipeDistance = 50

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe && !isEndGameAnimationRunning && !guess.isGuessMade) {
            handleGetNextClue()
        }
        else if (isRightSwipe && !isEndGameAnimationRunning && !guess.isGuessMade) {
            handleGetPreviousClue()
        }
    }

    const getQuestion = () => {
        switch (question) {
            case "WHAT":
                return "What am I?"
            case "WHO":
                return "Who am I?"
            case "WHERE":
                return "Where am I?"
            default:
                return "Loading"
        }
    }

    const getClueStyleForMobile = () => {
        if (isClueFlipped && isMobile) {
            return { transform: "rotateY(180deg)", }
        }
    }

    const getClueStyle = () => {
        if (height < 600 && isMobile) {
            return {
                width: 300,
                height: 175,
                marginTop: 10
            }
        }
    }

    const getClueTextStyle = () => {
        if (height < 600 && isMobile) {
            return {
                fontSize: 19,
                marginTop: -10
            }
        }
    }

    return isGame0ver && isSeeResultsShown ? (
        <div
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            <div
                style={getClueStyle()}
                onClick={() => isMobile ? setIsClueFlipped(!isClueFlipped) : null}
                class="flip-card-clue" >
                <div style={getClueStyleForMobile()} class="flip-card-inner">
                    <div class={`flip-card-front clue-${currentClue}`}>
                        <p
                            style={getClueTextStyle()}
                            className='front-card-clue-text'>
                            {clue ? clue.toUpperCase() : 'Your puzzle is loading'}
                        </p>
                        <FontAwesomeIcon icon={faRotateRight} className="rotate-icon" />
                    </div>
                    <div
                        style={getClueStyleForMobile()}
                        class="flip-card-back">
                        <p
                            style={getClueTextStyle()}
                            className='front-card-clue-text white-text'>
                            {clueDescription ? clueDescription.toUpperCase() : null}
                        </p>
                        <FontAwesomeIcon icon={faRotateRight} className="rotate-icon" />
                    </div>
                </div>
            </div >
        </div>
    ) : (
        <div
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
        >
            <div
                style={getClueStyle()}
                className={`clue clue-${currentClue}`}>
                <p className='riddle-question'>{getQuestion()}</p>
                <p
                    style={getClueTextStyle()}
                    className='front-card-clue-text front'>
                    {clue ? clue.toUpperCase() : 'Your puzzle is loading'}
                </p>
                <p className='swipe-instructions-text'>
                    {isMobile && currentClue === 0 ? "(Swipe here to go to the next clue)" : null}
                </p>
            </div>
        </div >
    )
}



export default Clue