import React from "react"
import downArrow from "../Images/downArrow.png"
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
const Instructions = ({ setAreInstructionsShown, setLocalStorage }) => {
    return (
        <div className='instructions'>
            <FontAwesomeIcon onClick={() => {
                setAreInstructionsShown(false)
                setLocalStorage("areInstructionsShown", false)
            }} icon={faXmark} className="close-pop-up" />
            <p className='instruction-text instruction-header'>How to play The Riddley</p>
            <p
                style={{ marginTop: -10 }}
                className='instruction-text goal-of-game'>Guess the mystery word using the fewest clues<br />
            </p>
            <li
                style={{ marginTop: -5 }}
                className='instruction-text bullet-text'>
                Type your guess into the box and click {isMobile ? '"Enter."' : '"Submit."'}
            </li>
            <li
                style={{ marginTop: 10 }}
                className='instruction-text bullet-text'>
                Guess the mystery word without making <span className="instruction-bold">3 mistakes!</span>
            </li>
            <p
                style={{ marginTop: 20 }}
                className='instruction-text instruction-header-1'>
                Things to know:
            </p>
            <li
                style={{ marginTop: 10 }}
                className='instruction-text bullet-text'>
                Each clue revealed is more helpful than the previous.
            </li>
            <li
                style={{ marginTop: 10 }}
                className='instruction-text bullet-text'>
                The mystery word may be a fictional. (Ex. Hogwarts)
            </li>
            <li
                style={{ marginTop: 10 }}
                className='instruction-text bullet-text'>
                Look out for clues with wordplay or double meanings!
            </li>
            <div style={{ marginTop: 10 }}>
                {[...Array(4).keys()].map((index) => {
                    if (index === 0) {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex", marginBottom: -10
                                }}>
                                <p
                                    style={{ marginLeft: 25, marginTop: 5, width: 23, height: 23 }}
                                    className={`grid-box grid-box-${index}`}>
                                </p>
                                <div style={{ display: "flex", marginTop: -10, marginLeft: 5 }} >
                                    <p>Most difficult Clues</p>
                                </div>
                            </div>
                        )
                    }
                    else if (index === 1) {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex", marginBottom: -35, marginTop: 2
                                }}>
                                <p
                                    style={{ marginLeft: 25, marginTop: 1, width: 23, height: 23 }}
                                    className={`grid-box grid-box-${index}`}>
                                </p>
                                <img className="down-arrow" alt="down arrpw" src={downArrow} />
                                <div style={{ display: "flex", marginTop: -10, marginLeft: 5 }} >
                                </div>
                            </div>
                        )
                    }
                    else if (index === 2) {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex", marginBottom: -15, marginTop: 2
                                }}>
                                <p
                                    style={{ marginLeft: 25, marginTop: 2.5, width: 23, height: 23 }}
                                    className={`grid-box grid-box-${index}`}>
                                </p>
                                <div style={{ display: "flex", marginTop: -10, marginLeft: 5 }} >
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex", marginBottom: -10
                            }}>
                            <p
                                style={{ marginLeft: 25, marginTop: 20, width: 23, height: 23 }}
                                className={`grid-box grid-box-${index}`}>
                            </p>
                            <div style={{ display: "flex", marginTop: 6, marginLeft: 5 }} >
                                <p>Least difficult Clues</p>

                            </div>
                        </div>
                    )
                })}
            </div>
            <button
                onClick={() => setAreInstructionsShown(false)}
                className="lets-play">
                Let's Play!
            </button>
        </div >
    )
}

export default Instructions