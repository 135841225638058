import React, { useState, useContext } from 'react'
import "../Styles/overlay.css"
import logo from "../Images/logo1.webp"
import { isMobile } from 'react-device-detect'
import { ContextProvider } from "../context"
const { CREATE_PUZZLE_URL } = require("../configs/activebuildconfig.json")


const Overlay = ({ puzzleAuthor, setIsOverlayShowing }) => {

    const { height, releaseDate } = useContext(ContextProvider)

    const [isOverlayShowingLocal, setIsOverlayShowingLocal] = useState(true)

    const getLogoStyle = () => {
        if (height < 600) {
            return {
                width: 175,
                height: 175,
                marginTop: 20
            }
        }
        if (height < 630 && isMobile) {
            return {
                width: 255,
                height: 255,
                marginTop: 0,
            }
        }
        if (height > 750 && isMobile) {
            return {
                width: 400,
                height: 400,
                marginTop: -60
            }
        }
        return {
            width: 300,
            height: 300,
            marginTop: -10
        }
    }

    const getPlayButtonStyle = () => {
        if (height < 600) {
            return {
                marginTop: -20,
            }
        }
    }

    return (
        <div className={isOverlayShowingLocal ? 'overlay-container' : 'overlay-container-fade-out'}>
            <div className='content-container'>
                <img
                    style={getLogoStyle()}
                    alt="logo"
                    className="logo fade-in-up"
                    src={logo} />
                <p className='game-title fade-in-up'>Riddley</p>
                {isMobile ?
                    height < 600 ? null : <p className='game-slogan fade-in-up'>Use the fewest clues to guess<br /> the correct word.</p>
                    :
                    <p className='game-slogan fade-in-up'>Use the fewest clues to guess the correct word.</p>
                }

                <button
                    onClick={() => {
                        setIsOverlayShowingLocal(false)
                        setTimeout(() => {
                            setIsOverlayShowing(false)
                        }, 1000);
                    }}
                    style={getPlayButtonStyle()}
                    className='play-button fade-in-up'>
                    Play
                </button>
                <p className='cover-date fade-in-up'>{releaseDate ? new Date(releaseDate?._seconds * 1000 + releaseDate?._nanoseconds / 1000000).toLocaleDateString("en", {
                    month: "long",
                    day: "numeric",
                    year: "numeric"
                }) : new Date().toLocaleDateString("en", {
                    month: "long",
                    day: "numeric",
                    year: "numeric"
                })}</p>
                <p className='author fade-in-up'>By {puzzleAuthor !== "" ? puzzleAuthor : "Anonymous"}</p>
                <p className='make-puzzle fade-in-up'>New puzzle out every weekday.</p>
            </div>
        </div>
    )
}

export default Overlay