import React, { useContext } from 'react'
import { ContextProvider } from "../context"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeleteLeft, faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/pro-light-svg-icons";
import "../Styles/keyboard.css"

const Keyboard = ({ guess, updateCurrentWordMobile, handleCrosswordSubmit,
    isSubmitEnabled, isGameOver, isSeeResultsShown, guessesRemaining,
    isEndGameAnimationRunning, handleSkipEndOfGameAnimation, handleGetNextClue,
    handleGetPreviousClue, currentClue }) => {
    const topRowLetters = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"]
    const middleRowLetters = ["A", "S", "D", "F", "G", "H", "J", "K", "L"]
    const bottomRowLetters = ["Enter", "Z", "X", "C", "V", "B", "N", "M"]

    const { isLoading, width, height } = useContext(ContextProvider)

    console.log(width)

    const handleEnterButtonColor = () => {
        if (isSubmitEnabled) {
            return {
                backgroundColor: "#68b4f3",
            }
        }
    }

    return (
        <>
            {!isSeeResultsShown ? < div
                className='keyboard-container'>
                <div className='inner-keyboard-container'>
                    <div className="keyboard-row">
                        {[...Array(10).keys()].map((index) => {
                            return <div
                                onClick={() => guess.isGuessMade || isGameOver || isLoading ? null : updateCurrentWordMobile(topRowLetters[index])}
                                className='keyboard-key keyboard-row-top'>
                                <span>{topRowLetters[index]}</span>
                            </div>
                        })}
                    </div>
                    <div className="keyboard-row keyboard-row-middle">
                        {[...Array(9).keys()].map((index) => {
                            return <div
                                onClick={() => guess.isGuessMade || isGameOver || isLoading ? null : updateCurrentWordMobile(middleRowLetters[index])}
                                className='keyboard-key middle-key'>
                                <span>{middleRowLetters[index]}</span>
                            </div>
                        })}
                    </div>
                    <div className="keyboard-row keyboard-row-bottom">
                        {[...Array(9).keys()].map((index) => {
                            if (index === 0) {
                                return <div
                                    style={handleEnterButtonColor()}
                                    onClick={() => handleCrosswordSubmit()}
                                    className='keyboard-key keyboard-key-enter'>
                                    <span>{bottomRowLetters[index]}</span>
                                </div>
                            }
                            if (index === 8) {
                                return <div
                                    className='keyboard-key delete-background-color'>
                                    <FontAwesomeIcon
                                        onClick={() => guess.isGuessMade || isGameOver || isLoading ? null : updateCurrentWordMobile("")}
                                        icon={faDeleteLeft}
                                        className="keyboard-key-delete" />
                                </div>
                            }
                            return <div
                                onClick={() => guess.isGuessMade || isGameOver || isLoading ? null : updateCurrentWordMobile(bottomRowLetters[index])}
                                className='keyboard-key'>
                                <span>{bottomRowLetters[index]}</span>
                            </div>
                        })}
                    </div>
                    <div className='flex-center'>
                        <FontAwesomeIcon
                            onClick={isEndGameAnimationRunning || guess.isGuessMade || isLoading ? null : handleGetPreviousClue}
                            icon={faCircleArrowLeft}
                            className={currentClue !== 0 && !isGameOver && !guess.isGuessMade && !isLoading ? "arrow-left-mobile" : "arrow-left-mobile arrow-disabled"} />
                        <FontAwesomeIcon
                            onClick={isEndGameAnimationRunning || guess.isGuessMade || isLoading ? null : handleGetNextClue}
                            icon={faCircleArrowRight}
                            className={currentClue < 11 && !isGameOver && !guess.isGuessMade && !isLoading ? "arrow-right-mobile" : "arrow-right-mobile arrow-disabled"} />
                        {isEndGameAnimationRunning ?
                            <button
                                onClick={() => handleSkipEndOfGameAnimation()}
                                className='skip-to-results-mobile'>
                                Skip to results
                            </button>
                            : <p className='guesses-remaining-mobile'>Guesses remaining:</p>
                        }
                    </div>
                    <div className='flex-center guesses-container'>
                        {isEndGameAnimationRunning ?
                            null
                            :
                            <>
                                {[...Array(4).keys()].map((index) => {
                                    //If the number of clues remaining is less than your index
                                    // Then you are invisible
                                    if (index >= guessesRemaining) {
                                        return <span key={index} className='guesses-remaining-bubble mobile-bubble guess-invisble'></span>
                                    }
                                    // If we are on the last clue and guess is false
                                    // Then add the animation class to the guess
                                    if (index === guessesRemaining - 1 && guess.isGuessMade && !guess.isCorrect) {
                                        return <span key={index} className='guesses-remaining-bubble mobile-bubble lose-guess-animation'></span>
                                    }
                                    return <span key={index} className='guesses-remaining-bubble mobile-bubble'></span>
                                })}
                            </>
                        }
                    </div>
                </div>
            </div> : null}
        </>
    )
}

export default Keyboard