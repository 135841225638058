
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - puzzle
 * FUNCTION - submitPuzzle
 * VERIFY - any user 
 * @param {*} puzzleNumber The puzzleNumber of the puzzle being submitted
 * @param {*} submissionData Data about the submission
 * @returns nothing 
 */

export const submitPuzzle = async (puzzleNumber, submissionData) => {
    const response = await fetch(`${API_ROOT_URL}/puzzle/submit_puzzle`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            puzzleNumber,
            submissionData
        })
    })
    const result = await response.json();
    return result;
}