import CryptoJS from "crypto-js"

export const decryptArray = (ciphertextArray) => {
    const plainTextArray = ciphertextArray.map((cipherText) => {
        const bytes = CryptoJS.AES.decrypt(cipherText, 'AreYouReallyCheatingOnAGame?GetALifeBro');
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText.replace(/^"(.+(?="$))"$/, '$1');
    })
    const jsonArray = plainTextArray.map((elem) => {
        return JSON.parse(elem)
    });

    return jsonArray
}