
import './App.css';
import GameBoard from './Components/GameBoard';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Context } from "./context"
function App() {
  return (
    <Router>
      <Context>
        <Routes>
          <Route path="/" element={
            <div className="App">
              <GameBoard />
            </div>
          } />
        </Routes>
      </Context>
    </Router>
  );
}

export default App;
