import React, { createContext, useState, useEffect } from 'react'
import { db, collection, query, where, onSnapshot } from '../firestore'
import { useNavigate } from 'react-router-dom';
import { getPuzzleByNumber } from '../Api/api';
import { useLocation } from "react-router-dom"
import utils from "../utils"
export const ContextProvider = createContext();
const { FRONTEND_URL } = require("../configs/activebuildconfig.json")


export const Context = ({ children }) => {

    const navigate = useNavigate()

    const setStateOrLocalStorage = (value, id) => {
        const localStorageItem = localStorage?.getItem(id)
        if (localStorageItem === null || localStorageItem === undefined || localStorageItem === "undefined") {
            return value
        }
        const localStorageValue = JSON?.parse(localStorageItem)
        // If the local storage is set, use that
        if (localStorageValue !== null && localStorageValue !== undefined) {
            return localStorageValue
        }
        // Otherwise just use the default state
        return value
    }

    const setLocalStorage = (id, value) => {
        localStorage.setItem(id, JSON.stringify(value))
    }

    // localStorage.clear();

    const search = useLocation().search;

    const puzzleNumberQueryParam = new URLSearchParams(search).get('puzzleNumber');

    const [riddleWord, setRiddleWord] = useState(setStateOrLocalStorage("Loading", "riddleWord"))

    const [puzzleNumber, setPuzzleNumber] = useState()

    const [resetStateFromContext, setResetStateFromContext] = useState(false)

    const [newestPuzzleNumber, setNewestPuzzleNumber] = useState(1)

    const [question, setQuestion] = useState("")

    const [puzzleAuthor, setPuzzleAuthor] = useState("")

    const [width, setWidth] = useState(document.body.clientWidth);

    const [height, setHeight] = useState(window.innerHeight);

    const [releaseDate, setReleaseDate] = useState(null);

    const [clues, setClues] = useState([
        "Loading...", "Loading...", "Loading...", "Loading...", "Loading...",
        "Loading...", "Loading...", "Loading...", "Loading...", "Loading...",
        "Loading...", "Loading...", "Loading...", "Loading...", "Loading...",
        "Loading...", "Loading...", "Loading...", "Loading...", "Loading...", "Loading..."
    ])

    const [isLoading, setIsLoading] = useState(true)

    const [areCluesShown, setAreCluesShown] = useState(setStateOrLocalStorage([
        true, false, false, false, false,
        false, false, false, false, false,
        false, false, false, false, false,
        false, false, false, false, false,
    ], "areCluesShown"))

    const [areCluesShownCopy, setAreCluesShownCopy] = useState(setStateOrLocalStorage([
        true, false, false, false, false,
        false, false, false, false, false,
        false, false, false, false, false,
        false, false, false, false, false,
    ], "areCluesShownCopy"))

    useEffect(() => {
        fetchPuzzleByNumber(puzzleNumberQueryParam);
    }, [])

    const resetContext = (riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate) => {
        setContext(riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate)
        setAreCluesShown([
            true, false, false, false, false,
            false, false, false, false, false,
            false, false, false, false, false,
            false, false, false, false, false,
        ])
        setAreCluesShownCopy([
            true, false, false, false, false,
            false, false, false, false, false,
            false, false, false, false, false,
            false, false, false, false, false,
        ])
    }

    const handleWindowSizeChange = () => {
        setWidth(document.body.clientWidth)
    }

    const handleWindowSizeHeight = () => {
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        // Fetch the new puzzle whenever a change is detected
        const activePuzzleQuery = query(collection(db, "activePuzzle"), where("puzzleStatus", "==", "ACTIVE"));
        const unsubscribe = onSnapshot(activePuzzleQuery, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const needsDecryption = false;
                const { riddleWord, clues, puzzleNumber, question, puzzleAuthor, releaseDate } = doc.data();
                const newestPuzzleNumber = parseInt(localStorage?.getItem("newestPuzzleNumber"));
                // If the new puzzleNumber greater than the newest puzzle number we currently have in localstorage
                if (puzzleNumber > newestPuzzleNumber) {
                    window.history.replaceState(null, null, `?puzzleNumber=${puzzleNumber}`);
                    setContext(riddleWord, clues, question, puzzleAuthor, puzzleNumber, puzzleNumber, releaseDate, needsDecryption);
                    localStorage.clear();
                    window.location.reload();
                }
            });
        });

        return () => unsubscribe()
    }, [])


    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('resize', handleWindowSizeHeight);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('resize', handleWindowSizeHeight);
        }
    }, []);

    const setContext = (riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate, needsDecryption = true) => {
        if (needsDecryption) {
            setRiddleWord(utils.decryptString(riddleWord))
            setClues(utils.decryptArray(clues))
        }
        else {
            setRiddleWord(riddleWord)
            setClues(clues)
        }
        setQuestion(question)
        setPuzzleAuthor(puzzleAuthor)
        setPuzzleNumber(puzzleNumber)
        setIsLoading(false)
        setNewestPuzzleNumber(newestPuzzleNumber)
        setReleaseDate(releaseDate)
        setLocalStorage("puzzleNumber", puzzleNumber)
        setLocalStorage("newestPuzzleNumber", newestPuzzleNumber)
    }

    const fetchPuzzleByNumber = async (puzzleNumber) => {
        const response = await getPuzzleByNumber(puzzleNumber)
        // If the response was not successful, show an error
        if (!response.success) {
            console.log("There was an error loading the puzzle")
            setClues([{ clueText: 'There was an error loading your puzzle. Please refresh the page.', clueDescription: '' }])
        }
        else {
            const { riddleWord, clues, puzzleNumber, newestPuzzleNumber, question, puzzleAuthor, releaseDate } = response.data
            window.history.replaceState(null, null, `?puzzleNumber=${puzzleNumber}`);
            // Check if the puzzleNumber in localStorage is different than the one we are getting from the database
            const puzzleNumberFromLocalStorage = JSON.parse(localStorage.getItem("puzzleNumber"))
            // If the puzzle number is the same AND they are not playing the newest puzzle
            // Then navigate the user to the newest puzzle
            if (puzzleNumberFromLocalStorage === puzzleNumber && puzzleNumber !== newestPuzzleNumber) {
                window.location.href = `${FRONTEND_URL}/?puzzleNumber=${newestPuzzleNumber}`
            }
            if (!puzzleNumberFromLocalStorage || puzzleNumberFromLocalStorage === puzzleNumber) {
                // If they are the same, that means the player is playing the same puzzle
                setContext(riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate)
            }
            else {
                // If they are different, that means the user used the URL to go to a different puzzle
                // So we need to reset the state and the context
                resetContext(riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate)
                setResetStateFromContext(true)
            }
        }
    }

    //Pass the user object to the context. 
    //Auth context will wrap around every component in app.js
    return (
        <ContextProvider.Provider value={{
            riddleWord, clues, question, areCluesShown, areCluesShownCopy, puzzleNumber, isLoading, resetStateFromContext,
            newestPuzzleNumber, puzzleAuthor, width, height, releaseDate,
            setStateOrLocalStorage, setRiddleWord, setClues,
            setAreCluesShown, setAreCluesShownCopy, setPuzzleNumber,
            setResetStateFromContext, resetContext, setLocalStorage, setNewestPuzzleNumber
        }}>
            {children}
        </ContextProvider.Provider>)
}