// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, query, where, onSnapshot, getFirestore, doc } from "firebase/firestore";




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD0O0KuW0W0OKkQ5czf-nJQcS8VsK7ReOw",
    authDomain: "questions-906fb.firebaseapp.com",
    projectId: "questions-906fb",
    storageBucket: "questions-906fb.appspot.com",
    messagingSenderId: "319117273069",
    appId: "1:319117273069:web:01fbd94b10f05a5ce429e4",
    measurementId: "G-6NM9S1G7QE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();


export {
    db, collection, query, where, onSnapshot
}