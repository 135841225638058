import React from 'react'
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment-timezone';
moment.tz.setDefault("America/New_York");

const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return
    } else {
        // Render a countdown
        return <span className='time-until-next-puzzle'>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>;
    }
};

const CountDownClock = () => {
    const currentTime = moment();
    const midnight = currentTime.clone().endOf('day');
    const timeUntilMidnight = midnight.diff(currentTime, 'seconds');
    return <Countdown
        date={currentTime + timeUntilMidnight * 1000}
        renderer={renderer}
    />
}

export default CountDownClock