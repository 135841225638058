
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")


/**
 * CONTROLLER - puzzle
 * FUNCTION - submitPuzzle
 * VERIFY - any user 
 * @param {*} puzzleNumber The puzzleNumber of the puzzle being submitted
 * @returns The stats of the puzzle 
 */

export const getStats = async (puzzleNumber) => {
    const response = await fetch(`${API_ROOT_URL}/puzzle/get_stats`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            puzzleNumber,
        })
    })
    const result = await response.json();
    return result;
}